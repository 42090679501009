.chevron::before {
  border-style: solid;
  border-width: 0.01em 0.01em 0 0;
  content: '';
  display: inline-block;
  height: 0.45em;
  left: 0;
  position: relative;
  top: 0.15em;
  transform: rotate(-45deg);
  vertical-align: top;
  width: 0.45em;
}
.chevron.right:before {
  left: 0;
  transform: rotate(45deg);
}
.chevron.bottom:before {
  top: 0;
  transform: rotate(135deg);
}
.chevron.left:before {
  left: 0em;
  transform: rotate(-135deg);
}
.close {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  overflow: hidden;
  transform: rotate(0);
  transition: 400ms all;
}
@media (max-width: 670px) {
  .close {
    width: 22px;
    height: 22px;
  }
}
.close:hover {
  transform: rotate(-90deg);
}
.close:hover::before,
.close:hover::after {
  background: $blue;
}
.close::before,
.close::after {
  content: '';
  position: absolute;
  height: 1px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: #858585;
}
.close::before {
  transform: rotate(45deg);
}
.close::after {
  transform: rotate(-45deg);
}
